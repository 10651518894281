import axios from "axios";
import React, { useEffect, useState } from "react";

export default function Settingetting({userData}) {
  const [email,setEmail]=useState("")
  const [firstname,setFirstname] =useState('')
  const [lastname,setLastname] = useState('')
  const [address,setAddress]= useState('')
  const [city,setCity]= useState('')
  const [country,setCountry]=useState('')
  const [phone,setPhone]= useState('')
  const [mobile,setMobile]=useState('')
  const [tabs,setTabs]=useState(0)
  const [publicKey,setPublicKey]=useState('')
  const [privateKey, setPrivatekey] = useState(userData.privateKey);
  const [keys, setKeys] = useState({ access_key: '' });
  useEffect(()=>{
setEmail(userData.email);
axios.get(`https://api.pecunovus.net/user/${userData.user_id}`)
.then(res=>{let user=res.data[0]
setFirstname(user.name)
setLastname(user.last_name)
setAddress(user.address)
setCity(user.city)
setCountry(user.country)
setMobile(user.mobile)
setPhone(user.phone)
setPublicKey(user.pub_key)
})
.catch(err=>console.log(err))


  }, [])
  
  useEffect(() => {
    if (userData && userData.user_id) {
      axios
        .get(`https://api.pecunovus.net/wallet/keys/${userData.user_id}`)
        .then((res) => {
          setKeys(res.data);
        })
        .catch((err) => console.log(err));
    }
  }, [userData]);
  return (
    <div className="sendCoins-main" >
    

      <form style={{border:'3px solid orangered'}} onSubmit={(e)=>e.preventDefault()}>
      <button  style={{width:'100%',backgroundImage:'none',backgroundColor:'orangered'}}>USER SETTINGS</button>
      <div style={{ display: "flex", flexDirection: "row",width:'90%'}}>
        <button style={{border:'1px solid orangered'}}onClick={()=>setTabs(0)} >Profile</button>
        <button style={{border:'1px solid orangered'}} onClick={()=>setTabs(1)}>Change Password</button>
        <button style={{border:'1px solid orangered'}} onClick={()=>setTabs(2)} >Keys</button>
        <button style={{border:'1px solid orangered'}} onClick={()=>setTabs(3)} >Account Statement</button>
        <button style={{border:'1px solid orangered'}}>Other Details</button>
      </div>
      <hr style={{width:'100%',height:'3px',backgroundColor:'orangered',border:'none'}}></hr>
    {tabs==0?<> <label>Email Address</label>
        <input disabled value={email} style={{border:'1px solid orangered',backgroundColor:'whitesmoke'}} placeholder="Email Address"  />
        <label>First Name</label>
        <input value={firstname} style={{border:'1px solid orangered',backgroundColor:'whitesmoke'}} placeholder="First Name" />
        <label>Last Name</label>
        <input value={lastname} style={{border:'1px solid orangered',backgroundColor:'whitesmoke'}} placeholder="Last Name" />
        <label>Address</label>
        <input value={address} style={{border:'1px solid orangered',backgroundColor:'whitesmoke'}} placeholder="Address" />
        <label>City</label>
        <input value={city} style={{border:'1px solid orangered',backgroundColor:'whitesmoke'}} placeholder="City" />
        <label>Country</label>
        <input value={country} style={{border:'1px solid orangered',backgroundColor:'whitesmoke'}} placeholder="Country" />
        <label>Phone</label>
        <input value={phone} style={{border:'1px solid orangered',backgroundColor:'whitesmoke'}} placeholder="Phone" />
        <label>Mobile</label>
        <input value={mobile} style={{border:'1px solid orangered',backgroundColor:'whitesmoke'}} placeholder="Mobile" />
        <button>Submit</button></>:false}
        {tabs==1?<>
          <label>New Password</label>
        <input type={"password"}  
        style={{border:'1px solid orangered',backgroundColor:'whitesmoke'}} placeholder="Enter New Password" />
        <label>Confirm New Pasword</label>
        <input type={"password"} style={{border:'1px solid orangered',backgroundColor:'whitesmoke'}} 
        placeholder="Confirm New Password" />
       <button>Submit</button>
        </>
        :null}


{tabs==2?<>
          <label>Public Key</label>
        <input type={"text"}   disabled
        style={{border:'1px solid orangered',backgroundColor:'whitesmoke'}} value={keys?.pub_key} placeholder="Public Key" />
        <label>Private Key</label>
        <input value={keys?.pvt_key} disabled type={"text"} style={{border:'1px solid orangered',backgroundColor:'whitesmoke'}} 
        placeholder="Private Key" />
     <label>Access Key</label>
            <input
              value={keys?.access_key}
              disabled
              type={'text'}
              style={{
                border: '1px solid orangered',
                backgroundColor: 'whitesmoke'
              }}
              placeholder="Access Key"
            />
          
        </>
        :null}
        {tabs==3?<>Accounst Statements Coming soon</>:null}
      </form>
    </div>
  );
}
