import React,{ useEffect, useState} from "react";
import "./main.css";
import {FaCoins} from 'react-icons/fa'
import {BsPiggyBankFill} from 'react-icons/bs'
import {GiReceiveMoney} from 'react-icons/gi'
import {SiBitcoinsv} from 'react-icons/si'
import {BsGraphUp} from 'react-icons/bs'
import {IoLogoUsd} from 'react-icons/io'
import axios from "axios";
import {MdQrCode2} from 'react-icons/md'
import Chart from "./chart";
import {HiTrendingDown, HiTrendingUp} from 'react-icons/hi'
import socket from '../../socket'
import InfiniteScroll from "react-infinite-scroll-component";
import {
  LineChart,
  ResponsiveContainer,
  Legend, Tooltip,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  AreaChart,
  Area
} from 'recharts';
import moment from "moment";
import logo from '../../Assets/displayicon.png'
import documega from '../../Assets/scroller5.png'
import sideDrawer from "../sideDrawer/sideDrawer";
import SideDrawer from "../sideDrawer/sideDrawer";
import QrCode from "../QR/Qr";
import { Grid } from "react-loader-spinner";
import NFTStation from "../NFT/NFTStation";
import SendCoins from "../SendCoins/sendCoins";
import PublicLedger from "../PublicLedger/PublicLedger";
import QRCode from "react-qr-code";
import Setting from "../settings/setting";
import CryptoLedger from "../PublicLedger/cryptoindex";
import {MdToggleOn,MdToggleOff} from 'react-icons/md';
export default function Main(props) {


  const userData=props.user

  const [user,setUser]=useState([])
  const [totalCoins,setTotalCoins] =useState('0.00000000')
  const [totalValue,setTotalValue] = useState('0.00000000')
  const [totalEscrowedCoins,setTotalEscrowedCoins] =useState('0.00000000')
  const [currentValue,setCurrentValue] = useState(0)
  const [chartData,setChartData]=useState([])
  const [circulatingSupply,setCirculatingSupply] =useState(281591051)
  const [coinVolume,setCoinVolume] =useState(114826.00453596)
  const [showQr,setShowQr]= useState(false)
  const [showNftStation,setShowNftStation] =useState(false)
  const [showPublicLedger,setShowPublicLedger] =useState(false)
  const [showCryptoIndexLedger,setCryptoIndexLedger] =useState(false)
  const [showSendCoin,setShowSendCoin] =useState(false)
  const [allowAccess,setAllowAccess]=useState(true)
  const [trend,setTrend] = useState({})
  const [showLedgerModal,setShowLedgerModal]=useState(false)
  const [modaldataLedger,setModaldataLedger]=useState({})
  const [privateKey,setPrivateKey] =useState(null)
  const [latestTransactions,setlatestTransactions]=useState([])
  const [welcome,setWelcome]= useState(false)
  const [showSetting,setShowSetting] =useState(false)
  const [showSideDrawer,setShowSideDrawer] = useState(false)
const [marketCap,setMarketCap] = useState(0)
const [limit,setLimit]=useState(0)
const [toggle,setToggle]=useState(false)
  const [quoteNumber, setQuoteNumber] = useState(Math.floor(Math.random() * 10))
  const [totalProjectToken, setTotalProjectToken] = useState([]);
  const [totalHoldingToken, setTotalHoldingToken] = useState([]);
  const [totalHoldingTokenCompany, setTotalHoldingTokenCompany] = useState([]);
  const [totalProjectTokenCompany, setTotalProjectTokenCompany] = useState([]);
  const [conversionToken_company, setConversionToken_company] = useState([])
  const [conversionToken, setConversionToken] = useState([])
  
useEffect(()=>{setQuoteNumber(Math.floor(Math.random()*10))},[])
const quotation=[
  {quote:"“I like friends who have independent minds because they tend to make you see problems from all angles.”",by:"Nelson Mandela"},
  {quote:"“No great discovery was ever made without a bold guess.”",by:"Issac Newton"},
  {quote:"“Tell me and I forget. Teach me and I remember. Involve me and I learn.”",by:"Benjamin Franklin"},
  {quote:"“Tell me and I forget. Teach me and I remember. Involve me and I learn.”",by:"Benjamin Franklin"},
  {quote:"“We cannot solve our problems with the same thinking we used when we created them.”",by:"Albert Einstein"},
  {quote:"“Be the change that you wish to see in the world.”",by:"Mahatma Gandhi "},
  {quote:"“I'm a success today because I had a friend who believed in me and I didn't have the heart to let him down.”",by:"Abraham Lincoln"},
  {quote:"“Innovation distinguishes between a leader and a follower”",by:"Steve Jobs"},
  {quote:"“The belief that the world is getting worse, that we can’t solve extreme poverty and disease, isn’t just mistaken. It’s harmful.”",by:"Bill Gates"},
  {quote:"“Success is a lousy teacher. It seduces smart people into thinking they can’t lose.”",by:"Bill Gates"}]
  useEffect(()=>{setShowQr(props.showQr)},[props.showQr])
  const pdata = [
    {
      "id": 1,
      "date_time": "2021-12-15",
      "chart_date": "2021-12-14",
      "chart_time": "23:56:52",
      "value": 60.05,
      "status": 1
    },
    {
      "id": 2,
      "date_time": "2021-12-16T17:30:01.000Z",
      "chart_date": "2021-12-15",
      "chart_time": "23:56:16",
      "value": 60.33,
      "status": 1
    },
    {
      "id": 3,
      "date_time": "2021-12-17T17:30:14.000Z",
      "chart_date": "2021-12-16T18:30:00.000Z",
      "chart_time": "23:56:47",
      "value": 60.57,
      "status": 1
    },
    {
      "id": 4,
      "date_time": "2021-12-18T17:30:01.000Z",
      "chart_date": "2021-12-17T18:30:00.000Z",
      "chart_time": "23:57:16",
      "value": 60.88,
      "status": 1
    },
    {
      "id": 5,
      "date_time": "2021-12-19T17:30:30.000Z",
      "chart_date": "2021-12-18T18:30:00.000Z",
      "chart_time": "23:57:12",
      "value": 61.22,
      "status": 1
    },
    {
      "id": 6,
      "date_time": "2021-12-20T17:30:43.000Z",
      "chart_date": "2021-12-19T18:30:00.000Z",
      "chart_time": "23:56:35",
      "value": 61.49,
      "status": 1
    },
    {
      "id": 7,
      "date_time": "2021-12-21",
      "chart_date": "2021-12-20",
      "chart_time": "23:56:59",
      "value": 61.67,
      "status": 1
    }
  ];

    const removeDuplicatedTokenCompany = (allData) => {
    for (let i = 0; i < allData.length; i++) {
      for (let j = i + 1; j < allData.length; j++) {
        if (allData[i].symbol == allData[j].symbol) {
          allData[i].assetAmount = allData[j].assetAmount + allData[i].assetAmount;
         
          allData = allData.filter((e) => e !== allData[j]);
        }
      }
    }

    for (let i = 0; i < allData.length; i++) {
      for (let j = i + 1; j < allData.length; j++) {
        if (allData[i].symbol == allData[j].symbol) {
          return removeDuplicatedTokenCompany(allData);
        }
      }
    }

    return allData;
  };
  const removeDuplicatedToken = (allData) => {
    for (let i = 0; i < allData.length; i++) {
      for (let j = i + 1; j < allData.length; j++) {
        if (allData[i].symbol == allData[j].symbol) {
          allData[i].wrapAmount = allData[j].wrapAmount + allData[i].wrapAmount;
          allData[i].initialFinal =
            allData[j].initialFinal + allData[i].initialFinal;
          allData = allData.filter((e) => e !== allData[j]);
        }
      }
    }

    for (let i = 0; i < allData.length; i++) {
      for (let j = i + 1; j < allData.length; j++) {
        if (allData[i].symbol == allData[j].symbol) {
          return removeDuplicatedToken(allData);
        }
      }
    }

    return allData;
  };

  const myConversionToken_company = (id) => {

    setConversionToken_company([])
    axios
      .get(`https://api.pecunovus.net/wallet/get_conversion_token_company?userId=${id}`)
      .then((res) => {
        if (res.data.status){
          setConversionToken_company(removeDuplicatedTokenCompany(res.data.token))
        // let total=res.data.token.reduce((a,b)=>a+(b.token_price*b.ownerTwoAmount),0)  
        //    setGlobalBalance((prev)=>Number(prev)+Number(total))

        }

      })
      .catch((error) => {
        console.log(error)
      });
  };
  const getMyHoldingTokenCompany = (id) => {
    setTotalHoldingTokenCompany([])

    if (id) {
      axios
        .get(`https://api.pecunovus.net/wallet/get_holding_token_company?userId=${id}`)
        .then((res) => {
          const data = res.data.token;
          setTotalHoldingTokenCompany(removeDuplicatedTokenCompany(data));
        
          // let total=res.data.data.reduce((a,b)=>a+(b.token_price*b.amount_issued),0)  
          // setGlobalBalance((prev)=>parseInt(prev)+parseInt(total))
        });
    }
  };
  const getMyProjectTokenCompany = (id) => {
    setTotalProjectTokenCompany([])
    //email = 'acc8@falconglobalacquisitions.com';
    if (id) {
  
      axios
        .get(`https://api.pecunovus.net/wallet/get_project_token_company?userId=${id}`)
        .then((res) => {
          const data = res.data.token;
          setTotalProjectTokenCompany(removeDuplicatedTokenCompany(data));
          // let total=res.data.data.reduce((a,b)=>a+(b.token_price*b.amount_issued),0)  
          // setGlobalBalance((prev)=>parseInt(prev)+parseInt(total))
        });
    }
  };

  const getChatData=()=>{
   let localData=[]
    axios.get('https://api.pecunovus.net/wallet/getChartData').then((res)=>{
   
      res.data.forEach(e=>{
      
        e.chart_date=moment(e.chart_date).local().format("YYYY-MM-DD")
       
         localData.push(e)
       if(localData.length==res.data.length){
         setChartData(localData)
       }
      })

    })
  }

  const get_current_index_coin=()=>{
  
     axios.get('https://api.pecunovus.net/wallet/get_current_index_coin').then((res)=>{
      
      setCurrentValue(res.data[0].value)
 
     })
   }

   const calculate_circulating_supply=()=>{
    
     axios.get('https://api.pecunovus.net/wallet/calculate_circulating_supply').then((res)=>{
   
      setCirculatingSupply(res.data.total_amount)
 
     })
   }

   
   const marketcap=()=>{
    
    axios.get('https://api.pecunovus.net/wallet/marketcap').then((res)=>{
  
     setMarketCap(res.data.MARKET_CAP)

    })
  }

   

   const calculate_daily_volume=()=>{
    
    axios.get('https://api.pecunovus.net/wallet/calculate_daily_volume').then((res)=>{
  
     setCoinVolume(res.data.total_volume)

    })
  }

  
  
const getMyCoins=(id)=>{
 
  axios.post('https://api.pecunovus.net/wallet/getMycoins',{
    user_id:id
  }).then((res)=>{
   
    setTotalCoins(res.data.total_coins)
    setTotalValue(res.data.value)
  })
}


const my_latest_transactions=(id)=>{
  axios.post('https://api.pecunovus.net/wallet/my_latest_transactions',{
    minLimit:limit,
    uid:id
  }).then((res)=>{
   setLimit(limit+10)
   let mydata=res.data
   if(latestTransactions.length>0){
      setlatestTransactions([...latestTransactions,...mydata])
   }else{
     setlatestTransactions(mydata)
   }
  
  })
}
  
const get_change_index_coin=()=>{
 
  axios.get('https://api.pecunovus.net/wallet/get_change_index_coin').then((res)=>{
   setTrend(res.data)
  })
}


const myEscrowedCoins=(id)=>{

  axios.post('https://api.pecunovus.net/wallet/myEscrowedCoins',{
    user_id:id
  }).then((res)=>{
   
    setTotalEscrowedCoins(res.data.total_coins)
  })
}


const get_pvt_key_by_uid=(id)=>{
  axios.post('https://api.pecunovus.net/wallet/get_pvt_key_by_uid',{
    uid:id
  }).then((res)=>{
   
    setPrivateKey(res.data)
  
  })
}

useEffect(()=>{
  window.addEventListener('beforeunload', function (e) {
    // Cancel the event
   
    socket.emit('pn-terminal-access-order',{privateKey:privateKey,toggle:false})
   
  
  });
},[privateKey])
useEffect(()=>{ socket.emit('joinRoompnprivateKey',{privateKey:privateKey})},[privateKey])
useEffect(()=>{socket.on("pn-terminal-access",(e)=>{
 
  if(e!==null &&privateKey!==null && e.privateKey===privateKey){
    //  if(data.toggle){
    //     setAllowAccess(true)
    //  }
    setAllowAccess(e.toggle)
     props.allowAccess(e.toggle)
  }
})},[privateKey])


useEffect(()=>{
  get_pvt_key_by_uid(userData.user_id)
 
  
},[])


useEffect(()=>{
  let allaccess=allowAccess
 if(allaccess){
  marketcap()
  get_change_index_coin()
  calculate_daily_volume()
  get_change_index_coin()
  calculate_circulating_supply()
  getChatData()
  get_current_index_coin()
  getMyCoins(userData.user_id)
  myEscrowedCoins(userData.user_id)
   my_latest_transactions(userData?.user_id)
   getMyHoldingTokenCompany(userData?.user_id);
   getMyProjectTokenCompany(userData?.user_id)
   myConversionToken_company(userData?.user_id)
 }
 
},[allowAccess])

useEffect(()=>{

 setInterval(() => {
   if (allowAccess) {
  marketcap()
  get_change_index_coin()
  calculate_daily_volume()
  get_change_index_coin()
  calculate_circulating_supply()
   getChatData()
  get_current_index_coin()
   getMyCoins(userData.user_id)
   myEscrowedCoins(userData.user_id)
   my_latest_transactions(userData?.user_id)
 }


}, 5200);
 
},[allowAccess])

useEffect(()=>{
  socket.emit('pn-terminal-access-order',{privateKey:privateKey,toggle:toggle})
 },[toggle])
// useEffect(()=>{
//   if(user.length>0){
//      getMyCoins()
//   }
 
// },[user])
useEffect(()=>{setShowSideDrawer(props.sideDrawer)},[props.sideDrawer])

const handlePecuTerminal=()=>{
  setShowNftStation(false)
  setShowQr(false)
  setShowPublicLedger(false)
  setShowSendCoin(false)
  setShowSetting(false)
  setCryptoIndexLedger(false)
  
}
useEffect((userData)=>{
  if(userData){
  
      setUser([userData]);
      // getMyCoins(userData)

  }
},[userData])

  return (
    allowAccess? <div className="main">
      {showNftStation? <NFTStation />:null}
      {showPublicLedger? <PublicLedger />:null}
      {showCryptoIndexLedger? <CryptoLedger />:null}
       {showSendCoin?<SendCoins userData={userData} />:null}
       {showSetting?<Setting privateKey={privateKey} userData={userData} />:null}
   {showSideDrawer? <div onMouseLeave={()=>{setShowSideDrawer(false)}} style={{position:'sticky',left:'0px',width:'100%',top:'0px',padding:'0px',margin:'0px',zIndex:'9999'}}>
     <SideDrawer showSendCoin={()=>{setShowSetting(false);setShowSendCoin(!showSendCoin);setShowNftStation(false);setShowPublicLedger(false);setCryptoIndexLedger(false)}} 
      showNftStation={()=>{setShowNftStation(!showNftStation);setShowPublicLedger(false);setCryptoIndexLedger(false);setShowSendCoin(false);setShowSetting(false)}}
       showPublicLedger={()=>{setShowPublicLedger(!showPublicLedger);setCryptoIndexLedger(false);setShowNftStation(false);setShowSendCoin(false);setShowSetting(false)}} 
       showSetting={()=>{setShowPublicLedger(false);setCryptoIndexLedger(false);setShowNftStation(false);setShowSendCoin(false);setShowSetting(!showSetting)}}
       showCryptoIndexLedger={()=>{setShowPublicLedger(false);setCryptoIndexLedger(!showCryptoIndexLedger);setShowNftStation(false);setShowSendCoin(false);setShowSetting(false)}} 
      
     pecuTerminal={()=>{handlePecuTerminal()}} showQr={()=>{setShowQr(true)}} sideDrawer={props.sideDrawer} /></div>:null} 
  
      <div className="main-up">
     
  {showQr?<QrCode closeQr={()=>{setShowQr(false);props.closeQr()}} publicKey={userData.pub_key} />:null}
  {showLedgerModal?
        <div className='QR-home' style={{backgroundColor:'black',width:'80%',justifyContent:'space-between',maxHeight:'400px',overflowX:'hidden',overflowY:'auto'}}>
        <div className='header-Qr' style={{backgroundColor:'orangered'}}>
            <span style={{fontSize:'1.2rem',color:'white',fontWeight:400}}> PRIVATE LEDGER</span>
            <button className='close-btn' style={{width:'45px'}} onClick={()=>setShowLedgerModal(false)}>Close</button>
        </div>
        <h2 style={{color:'orangered',fontWeight:400}}>BLOCK DETAILS</h2>
        <div className="row" style={{width:'85%',overflowX:'clip',color:'white',fontWeight:400}} >Sender's Public Key:</div>
 
        <div className="row" style={{maxWidth:'85%',overflowX:'clip',color:'white',width:'85%',fontWeight:400}} >{modaldataLedger?.public_key}</div>
       <br></br>
       <div className="row" style={{width:'85%',overflowX:'clip',color:'white',fontWeight:400}} >Value:</div>
       
        <div className="row" style={{color:'white',width:'85%',fontWeight:400}}>{modaldataLedger?.value} PECU {`( $${((modaldataLedger?.value)*currentValue).toLocaleString('en-US')} USD )`}</div>
        <br></br>
        <div className="row" style={{width:'85%',overflowX:'clip',color:'white',fontWeight:400}} >Receiver's Public Key:</div>
       
        <div className="row" style={{overflowX:'hidden',maxWidth:'85%',overflowX:'clip',color:'white',width:'85%',fontWeight:400}}>{modaldataLedger?.to_public_key}</div>
        <br></br>
        <div className="row" style={{width:'85%',overflowX:'clip',color:'white',fontWeight:400}} >Block Address:</div>
       
       <div className="row" style={{overflowX:'hidden',maxWidth:'85%',overflowX:'clip',color:'white',width:'85%',fontWeight:400}}>{modaldataLedger?.block_address}</div>
       <br></br>
       <br></br>
        <div className="row" style={{width:'85%',overflowX:'clip',color:'white',fontWeight:400}} >Block Size:</div>
       
       <div className="row" style={{overflowX:'hidden',maxWidth:'85%',overflowX:'clip',color:'white',width:'85%',fontWeight:400}}>{modaldataLedger?.block_address.length} bytes</div>
       <br></br>
       <br></br>
        <div className="row" style={{width:'85%',overflowX:'clip',color:'white',fontWeight:400}} >Block Height:</div>
       
       <div className="row" style={{overflowX:'hidden',maxWidth:'85%',overflowX:'clip',color:'white',width:'85%',fontWeight:400}}>{modaldataLedger?.id} </div>
       <br></br>
       <br></br>
        <div className="row" style={{width:'85%',overflowX:'clip',color:'white',fontWeight:400}} >Contract Key:</div>
       
       <div className="row" style={{overflowX:'hidden',maxWidth:'85%',overflowX:'clip',color:'white',width:'85%',fontWeight:400}}>{modaldataLedger?.contract_key}</div>
       <br></br>
        <div className="row" style={{width:'85%',overflowX:'clip',color:'white',fontWeight:400}} >Date and Time of Tranaction:</div>
       
        <div className="row" style={{color:'white',width:'85%',fontWeight:400}}>{new Date(modaldataLedger?.date_time).toLocaleString() }</div>
      
          </div>
      :null}
    {welcome?
     <div className='QR-home' style={{backgroundColor:'black'}}>
     <div className='header-Qr' style={{backgroundColor:'orangered'}}>
         <span style={{fontSize:'1.2rem',color:'white'}}>PECU NOVUS TERMINAL</span>
         <button className='close-btn' style={{width:'45px'}} onClick={()=>setWelcome(false)}>Close</button>
     </div>
     <div className='QR'>
        <img src={logo} width="100px" />
        
       
    <h2 style={{color:'orangered'}}>Welcome to Pecu Novus Terminal</h2>
     
  
       
       
     </div>
  
     <p style={{fontStyle:'italic'}}>{quotation[quoteNumber].quote} ~ {quotation[quoteNumber].by}</p>
      <img src={documega} width="100%"  />
     
       </div>
       
    :null}
     <div className="main-left">
     <div className="main-sub-box">
            <div className="total-coin-title" style={{color:trend.trend=='-1'?"red":"green",}}>{toggle?"You are connected to your Terminal":"You are disconnected from your Terminal"}</div>
           
           {!toggle?<MdToggleOff size={50} onClick={()=>setToggle(!toggle)} color="red" />:
           <MdToggleOn size={50}  onClick={()=>setToggle(!toggle)}  color="green" />} 
            
          </div>
          <div className="main-sub-box">

            <div className="total-coin-title"><img src ={logo} width="30px" />PECU HOLDINGS</div>
            {/* <FaCoins style={{margin:'1rem'}} size={40} color="goldenrod" /> */}
            <div className="total-coin-value" style={{display:'flex',flexDirection:'row',justifyContent:'space-between',width:'80%'}}><span>Total : </span>{totalCoins}</div>
            <div className="total-coin-value" style={{display:'flex',flexDirection:'row',justifyContent:'space-between',width:'80%'}} ><span>Escrowed :</span>{totalEscrowedCoins}</div>
            <div className="total-coin-value" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '80%' }} ><span>Value :</span><span><IoLogoUsd />{parseFloat(totalValue).toLocaleString('en-US')} USD</span></div>
            
          </div>
      
        </div>
        <div className="main-sub-box-row" style={{maxHeight:'60px',maxWidth:'90%',fontSize:'14px',color:'white'}}>Current Value
     
          <span style={{ color: trend.trend == '-1' ? "red" : "green", fontSize: '13px' }}>{`$${currentValue.toLocaleString('en-US')} ` }<br></br>{trend.trend=='-1'?"":"+"} {trend.value}</span>
</div>
         <div className="main-sub-box-row" style={{maxHeight:'60px',maxWidth:'90%',fontSize:'14px'}}>Volume<span>{`$${parseFloat(((parseFloat(coinVolume)*parseFloat(currentValue)).toFixed(2))).toLocaleString('en-US')}`}</span>
</div>
<div className="main-sub-box-row" style={{maxHeight:'60px',maxWidth:'90%',fontSize:'14px'}}>Market Cap
<span>${parseInt(marketCap).toLocaleString('en-US')}</span>
</div>
        <div className="main-right-chart" >
          {chartData.length>0?
          <ResponsiveContainer width="100%" height={300}>
        <AreaChart  data={chartData}
  margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
  <defs>
    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
      <stop offset="5%" stopColor="orange" stopOpacity={0.8}/>
      <stop offset="95%" stopColor="orange" stopOpacity={0}/>
    </linearGradient>
    <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
      <stop offset="5%" stopColor="orange" stopOpacity={0.8}/>
      <stop offset="95%" stopColor="orange" stopOpacity={0}/>
    </linearGradient>
  </defs>
  <XAxis dataKey="chart_date" />
  <YAxis />
  <CartesianGrid strokeDasharray="3 3" />
  <Tooltip />
  <Area type="monotone" dataKey="value" stroke="orange" fillOpacity={1} fill="url(#colorUv)" />
 
</AreaChart>
            </ResponsiveContainer>
            
         :<Grid color="#00BFFF" height={80} width={80} />}
     
        </div>
    
      
      </div>
   
      <div className="main-down" >
      <div className="total-coin-title"> TOKEN HOLDINGS</div>
      
        {totalHoldingTokenCompany.map((token, index) => {
          return  <div key={index} className="main-sub-box-row" style={{maxHeight:'60px',maxWidth:'90%',fontSize:'14px',color:'orange',flexDirection:'row'}}>{token?.symbol}
      <span style={{fontSize:'13px'}}>{ `Available : ${token?.assetAmount} `}</span>
     
</div>
        })}

         {totalProjectTokenCompany.map((token, index) => {
          return  <div key={index} className="main-sub-box-row" style={{maxHeight:'60px',maxWidth:'90%',fontSize:'14px',color:'orange',flexDirection:'row'}}>{token?.symbol}
      <span style={{fontSize:'13px'}}>{ `Available : ${token?.assetAmount} `}</span>
     
</div>
         })}
          {conversionToken_company.map((token, index) => {
          return  <div key={index} className="main-sub-box-row" style={{maxHeight:'60px',maxWidth:'90%',fontSize:'14px',color:'orange',flexDirection:'row'}}>{token?.symbol}
      <span style={{fontSize:'13px'}}>{ `Available : ${token?.assetAmount} `}</span>
     
</div>
        })}
      {/* <div className="main-sub-box" style={{maxHeight:'40px',maxWidth:'110px',fontSize:'14px',color:trend.trend=='-1'?"red":"green"}}>24h Change
      
      <span>{trend.trend=='-1'?"":"+"} {trend.value}</span>
</div> */}
    
      {/* <div className="main-sub-box" style={{maxHeight:'40px',maxWidth:'110px',fontSize:'14px'}}>Coin Volume<span>{parseFloat(coinVolume).toFixed(8).toLocaleString('en-US')}</span></div> */}
     
{/* <div className="main-sub-box" style={{maxHeight:'40px',maxWidth:'115px',fontSize:'14px'}}>Circulating Supply
<span>{parseInt(circulatingSupply).toLocaleString('en-US')}
</span>

</div> */}
{/* <div className="main-sub-box" style={{maxHeight:'40px',maxWidth:'110px',fontSize:'14px'}}>Max Supply
<span>1,000,000,000
</span>

</div> */}



      </div>
   
   
    </div>:<div>
      <div className="main webscanner" style={{minHeight:'100vh'}} >
   
    <div className='QR-home' style={{border:''}}>
<div className='header-Qr' style={{backgroundColor:'rgb(255, 145, 0)'}}>
    <span style={{fontSize:'1.2rem'}}>PECU NOVUS DIGITAL WALLET SCANNER CODE</span>
    {/* <button className='close-btn' onClick={()=>closeQr()}>X</button> */}
</div>
<div className='QR'>
<p >Scan This QR Code with your PECU NOVUS Mobile Wallet to access the terminal</p>

  {privateKey? <QRCode value={privateKey} bgColor='#ffffff' /> :"Loading,Please wait"}
  
  
</div>

<p >Note:If you already scanned it earlier,just turn on the Terminal Connection from your PECU NOVUS Mobile Wallet to access the terminal </p>

  </div>;
   </div>
   <div className="main downloadApp">

<div className='QR-home' style={{border:''}}>
<div className='header-Qr' style={{backgroundColor:'rgb(255, 145, 0)'}}>
    <span style={{fontSize:'1.2rem'}}>Download App</span>
    {/* <button className='close-btn' onClick={()=>closeQr()}>X</button> */}
</div>
<div style={{display:'flex',flexDirection:'column',justifyContent:'space-evenly',alignItems:'center'}} >
<a className="downloadbtn" href="https://pecunovus.net/Android/fhhf6758758fdffhsfjuyvufgfu87346587bfg835t578fg873t587gsfhg783465fkjf/PecuNovusWallet.apk" >Download Android App</a>
<button  className="downloadbtn">Download IOS App</button>
  
</div>

  </div>
</div>
    </div>
  );
}
