import React,{useState,useEffect} from "react";
import "./publicLedger.css";
import axios from 'axios';
import InfiniteScroll from "react-infinite-scroll-component";
export default function PublicLedger() {
 const [showModal,setShowModal]=useState(false)
 const [modaldata,setModaldata]=useState({})
    // const data=[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15]
  const [data,setData]=useState([])
  const [limit,setLimit]=useState(0)
  const makeid=(length)=> {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * 
  charactersLength));
   }
   return result;
  }


    useEffect(()=>{
axios.post(`https://api.pecunovus.net/wallet/public_ledger_all_data`,{
  minLimit:limit,
  maxLimit:limit+5
})
.then((res)=>{
  setLimit(limit+10)
  let mydataf=[]
  res.data.forEach(e=>{
    if(e.key &&e.key.length>0){
      mydataf.push(e)
    }else{
      e.key=makeid(128)
      mydataf.push(e)
    }

    if(res.data.length==mydataf.length){
       setData(mydataf)
    }
  })
 
})
    },[])


  const fetchmore=()=>{
    axios.post(`https://api.pecunovus.net/wallet/public_ledger_all_data`,{
      minLimit:limit,
      maxLimit:limit+10
    })
    .then((res)=>{
      setLimit(limit+10)
      let myData=res.data
      let mydataf=[]
      res.data.forEach(e=>{
        if(e.key &&e.key.length>0){
          mydataf.push(e)
        }else{
          e.key=makeid(128)
          mydataf.push(e)
        }
    
        if(res.data.length==mydataf.length){
           setData([...data,...mydataf])
        }
      })

    
    })
  }


  return (
    <div className="publicLedger-main"  >
      <div className="total-coin-title" style={{fontSize:'1.5rem',fontWeight:'500'}}>PUBLIC LEDGER</div>
      {showModal?
        <div className='QR-home'  style={{backgroundColor:'black',width:'80%',justifyContent:'space-between',maxHeight:'400px',overflowX:'hidden',overflowY:'auto'}}>
        <div className='header-Qr' style={{backgroundColor:'orangered'}}>
            <span style={{fontSize:'1.2rem',color:'white'}}>Public Ledger</span>
            <button className='close-btn' style={{width:'45px'}} onClick={()=>setShowModal(false)}>Close</button>
        </div>
        <h2 style={{color:'orangered'}}>Transaction Details</h2>
        <div className="row" style={{width:'85%',overflowX:'clip',color:'white',fontWeight:'400'}} >Sender's Public Key:</div>
 
        <div className="row" style={{maxWidth:'85%',overflowX:'clip',color:'white',width:'85%',fontWeight:'400'}} >{modaldata?.public_key}</div>
       <br></br>
       <div className="row" style={{width:'85%',overflowX:'clip',color:'white',fontWeight:'400'}} >Value:</div>
       
        <div className="row" style={{color:'white',width:'85%',fontWeight:'400'}}>{modaldata?.value}</div>
        <br></br>
        <div className="row" style={{width:'85%',overflowX:'clip',color:'white',fontWeight:'400'}} >Receiver's Public Key:</div>
       
        <div className="row" style={{overflowX:'hidden',maxWidth:'85%',overflowX:'clip',color:'white',width:'85%',fontWeight:'400'}}>{modaldata?.to_public_key}</div>
        <br></br>
        <div className="row" style={{width:'85%',overflowX:'clip',color:'white',fontWeight:'400'}} >Date and Time of Transaction:</div>
       
        <div className="row" style={{color:'white',width:'85%',fontWeight:'400'}}>{new Date(modaldata?.date_time).toLocaleString() }</div>
        <br></br>
        <div className="row" style={{width:'85%',overflowX:'clip',color:'white',fontWeight:400}} >Block Address:</div>
 
       
       <div className="row" style={{overflowX:'hidden',maxWidth:'85%',overflowX:'clip',color:'white',width:'85%',fontWeight:400}}>{modaldata?.key}</div>
       <br></br>
       <br></br>
        <div className="row" style={{width:'85%',overflowX:'clip',color:'white',fontWeight:400}} >Block Size:</div>
       
       <div className="row" style={{overflowX:'hidden',maxWidth:'85%',overflowX:'clip',color:'white',width:'85%',fontWeight:400}}>{128} bytes</div>
       <br></br>
       <br></br>
        <div className="row" style={{width:'85%',overflowX:'clip',color:'white',fontWeight:400}} >Block Height:</div>
       
       <div className="row" style={{overflowX:'hidden',maxWidth:'85%',overflowX:'clip',color:'white',width:'85%',fontWeight:400}}>{modaldata?.tid} </div>
       <br></br>
       <br></br>
        <div className="row" style={{width:'85%',overflowX:'clip',color:'white',fontWeight:400}} >Contract Key:</div>
       
       <div className="row" style={{overflowX:'hidden',maxWidth:'85%',overflowX:'clip',color:'white',width:'85%',fontWeight:400}}>{modaldata?.contract_key}__</div>
       <br></br>
       <div className="row" style={{width:'85%',overflowX:'clip',color:'white',fontWeight:400}} >Notes:</div>
       
       <div className="row" style={{overflowX:'hidden',maxWidth:'85%',overflowX:'clip',color:'white',width:'85%',fontWeight:400}}>{modaldata?.note}</div>
       <br></br>
          </div>
      :null}
      
      <InfiniteScroll 
        dataLength={data.length}
        next={fetchmore}
        hasMore={true}
        loader={<div></div>}
      >
        <div style={{width:'95vw'}}>{data.length>0?data.map((e,index)=>( <div className="table" onClick={()=>{setShowModal(true);setModaldata(e)}}>
   
        <div className="row" style={{maxWidth:'150px',overflowX:'clip'}} >{e.public_key}</div>
        <div className="row">{e.value}</div>
        <div className="row" style={{maxWidth:'150px',overflowX:'clip'}}>{e.to_public_key}</div>
        <div className="row">{new Date(e.date_time).toLocaleString() }</div>
      </div>)):"Loading"}</div>
  
      </InfiniteScroll>
    
    
    
    </div>
  );
}
