import React, { useEffect, useState } from 'react';
import './header.css';
import logo from '../../Assets/icon.ico'
import {IoIosArrowDropdownCircle, IoIosArrowDropupCircle, IoMdArrowDropdown, IoMdArrowDropdownCircle, IoMdArrowDropup, IoMdArrowDropupCircle} from 'react-icons/io'
import {CgMenuGridO, CgMenuGridR} from 'react-icons/cg'
import {GiHamburgerMenu} from 'react-icons/gi'
import { MdQrCode2 } from 'react-icons/md';
import { FaClock } from 'react-icons/fa';
import axios from 'axios';
export default function Header(props) {
    const [sideDrawer,setSideDrawer] =useState(false)
    const [showDropdown,setShowDropdown]=useState(false)
     const userData=props.user
    
    const [user,setUser]=useState([])
    const [localTime,setLocalTime]=useState(new Date().toLocaleTimeString())
    const [dropDownBox,showDropDownBox]=useState(false)

    const updatepubkey=(id)=>{
   
      axios.post(`https://api.pecunovus.net/wallet/update-public-key`,{user_id:id})
      .then(res=>{if(res.data.status==200){

      props.updatepubkey(res.data.pub_key)
      
      
      }}).catch(err=>console.log(err))

    }
    useEffect(()=>{
        if(userData){
            setUser([userData])
        }
    },[userData])

    useEffect(()=>{
      setInterval(() => {
       setLocalTime(new Date().toLocaleTimeString())
      }, 1000);
    })


    useEffect(()=>{
      props.sideDrawer(sideDrawer)
    },[sideDrawer])

    useEffect(()=>{setSideDrawer(props.resetSidebar)},[props.resetSidebar])
  return <div className='header'>
      <div className='header-left'>
     {user.length>0? sideDrawer?<GiHamburgerMenu className='menu-open' size={30}
     onClick={()=>{setSideDrawer(false)}} 
     />:<GiHamburgerMenu className='menu-open' size={30}
     onClick={()=>{setSideDrawer(true)}} 
     />:null}
    
     <a href='/' className='logo'><img src={logo} /></a>
     <h3 style={{color:'rgb(255, 145, 0)'}}>Pecu Novus Wallet</h3>
    {/* {user.length==0?  <a href="https://pecunovus.com" style={{textDecoration:'none'}} target={"_blank"}><h5 style={{textAlign:'center',color:'orangered'}} >Learn More About The Pecu Novus Digital Asset Network</h5></a> :null} */}
      </div>
      {/* {props.allowAccess&&user.length&& user[0].pub_key? <div className="header-center"> 
     <p>PUBLIC KEY:</p>
     <p className='public_key'>
       {user.length?user[0].pub_key:""}</p>
       <button>Copy</button>
       <button style={{cursor:'pointer'}} onClick={()=>{updatepubkey(user[0]?.user_id)}}>Update</button>
     <MdQrCode2 style={{cursor:'pointer',padding:'5px'}}  size={20} onClick={()=>props.showQr(true)} />
  <span style={{width:'110px',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center'}}> 
  <FaClock size={"13px"} style={{margin:'3px'}} /> {localTime}</span>
     </div>:null} */}
     
    {/* <div className="header-center">YOUR PECU ADDRESS : g8MszDg1E3OMOAmn...</div> */}
     {user.length&& user[0].pub_key?  <div className='header-right'>
       
          <div className='username-header'>
            {/* <span >{props.allowAccess&&user.length?"TERMINAL ID: ":null} {user.length>0? props.allowAccess?`PN${user[0]?.pub_key?.slice(0,6)}${user[0]?.user_id}`:"":"login"}</span> */}
          <button style={{margin:'5px'}} onClick={()=>{setShowDropdown(!showDropdown);props.logOut()}}>Logout</button>
         
          
          </div>
      </div>:null}
    
      
  </div>;
}
