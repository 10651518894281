import React, { useState } from "react";
import PrivacypolicyEscrow from "../../privacypolicyEscrow";
import PrivacyPolicyNFT from "../../privacyPolicyNFT";
import "./nft.css";
export default function NFTStation() {
  const [previewSrc, setPreviewSrc] = useState("");
  const [fileType, setFileType] = useState("");
  const [checkbox,setCheckbox]=useState(false);
  const fileChangeHandler = (e) => {
    const file = e.target.files[0];
    setFileType(file.type);
    setPreviewSrc(URL.createObjectURL(file));
  };
  return (
    <div className="nft-main">
      <h3 className="nft-heading">NFT MINTING TERMINAL (ART)</h3>
      <div
        className="main-up "
        style={{ alignItems: "flex-start", justifyContent: "space-evenly" }}
      >
        <div
          className="main-left"
          style={{
            alignItems: "flex-start",
            backgroundColor: "#04102c",
            margin: "1rem",
            flex: "0.5",
            boxShadow: "1px 1px 1px 2px green",
            padding: "1rem",
          }}
        >
          <form className="form-nft" onSubmit={(e) => e.preventDefault()}>
            <h3 className="nft-heading">CREATE NFT ITEM</h3>

            <label  htmlFor="nftfile">Upload File</label>
            <input disabled
            style={{cursor:'pointer'}}
              name="nftfile"
              accept="image/png, image/jpeg, video/mp4"
              type="file"
              onChange={fileChangeHandler}
            />

            {previewSrc !== "" && fileType.substring(0, 5) == "image" ? (
              <img
                src={previewSrc}
                style={{ width: "80%", borderRadius: "10px" }}
              />
            ) : null}
            {previewSrc !== "" && fileType.substring(0, 5) == "video" ? (
              <video
                controls={true}
                src={previewSrc}
                style={{ width: "80%", borderRadius: "10px" }}
              />
            ) : null}
            {/* <label>Thumbnail</label>
        <input type="file" /> */}
            <label>NFT Name</label>
            <input maxlength="35" size="35" placeholder="name" required />
            <label>Description</label>
            <textarea rows={4} placeholder="description" />

            <label>Series Of</label>

            <input placeholder="" value="1" maxlength="2" size="3" />
            <label>Currency :</label>
            <input
              type="text"
              name="curr"
              placeholder=""
              maxlength="4"
              size="5"
              value="PECU"
              disabled
            />
            <label>Price :</label>

            <input
              type="text"
              name="price"
              placeholder=""
              maxlength="12"
              size="15"
              required
            />

            <label>OWNERS EMAIL</label>
            <input placeholder="OWNERS EMAIL" required />
          <span style={{maxHeight:'200px',overflowY:'auto'}}>  {checkbox?<PrivacyPolicyNFT />:null} 
          <input  type={"checkbox"} id="checkbox" value={checkbox} onChange={(e)=>setCheckbox(!checkbox)} />
            <label> I agree to NFT MINTING Terms & Conditions</label>
          </span>
         
            <button disabled>CREATE NFT</button>
          </form>
        </div>
        {/* -------------------------------CREATE_NFT_ENDS------------------------- */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            flexWrap: "wrap",
            flex: 0.4,
            margin: "1rem",
            boxShadow: "1px 1px 1px 2px green",
            backgroundColor: "#04102c",
            borderRadius: "5px",
            padding: "1rem",
          }}
        >
          <h3
            className="nft-heading"
            style={{ textAlign: "center", width: "100%" }}
          >
            NFT EXPLORER
          </h3>
          <div
            className="main-sub-box"
            style={{
              minWidth: "90%",
              minHeight: "140px",
              boxShadow: "1px 1px 1px 2px green",
            }}
          >
            <input type="text" placeholder="NFT PUBLIC KEY" />
            <button>VIEW NFT</button>
          </div>
          <div
            className="main-sub-box"
            style={{
              minWidth: "90%",
              minHeight: "140px",
              boxShadow: "1px 1px 1px 2px green",
            }}
          >
            <input type="text" placeholder="NFT PUBLIC KEY" />
            <button>VIEW NFT HISTORY</button>
          </div>
          <div
            className="main-sub-box"
            style={{
              minWidth: "90%",
              maxHeight: "70px",
              boxShadow: "1px 1px 1px 2px green",
            }}
          >
            <button>SHOW ALL NFT </button>
          </div>
        </div>
      </div>
      <div className="main-down" style={{ minHeight: "40vh" }}></div>
    </div>
  );
}
