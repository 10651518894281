import React from 'react';
import './sideDrawer.css'
export default function SideDrawer(props) {
  return <div className={props.sideDrawer?'sideDrawer show':'sideDrawer'} >
      <div className="sidedrawer__box">
          {/* <h5>STATION</h5> */}
          <button onClick={()=>{props.pecuTerminal()}} style={{backgroundColor:'#1d3fad'}} >PECU Station</button>
          {/* <button onClick={()=>{props.showCryptoIndexLedger()}} style={{backgroundColor:'brown'}} >Crypto Station</button>
          <button onClick={()=>{props.showNftStation()}} style={{backgroundColor:'green'}} >NFT Minting </button>
           */}
      </div>
      <div className="sidedrawer__box">
          {/* <h5>LEDGERS</h5> */}
          {/* <button onClick={()=>{props.showPublicLedger()}} style={{backgroundColor:'orange'}} >Public Ledger</button>
          */}
          <button onClick={()=>{props.showSendCoin()}} style={{backgroundColor:'orange'}} >Transfer</button>
         
          
      </div>
      <div className="sidedrawer__box">
          {/* <h5>USER PANEL</h5> */}
        
          <button onClick={()=>props.showQr(true)} style={{backgroundColor:'grey'}} >QR Address</button>
          <button onClick={()=>props.showSetting(true)} style={{backgroundColor:'grey'}} >Settings</button>
      </div>
  </div>;
}
