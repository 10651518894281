import React, { useState } from "react";
import { FaUserShield } from "react-icons/fa";
import "../Login/login.css";
import axios from "axios";
import logo from '../../Assets/displayicon.png'
import loginRight from '../../Assets/login-right.png'
export default function Signup(props) {
  
    const [field, setField] = useState({
        email: "",
        password: "",
        firstname:"",
        lastname:"",
    });
    const [error,setError] =useState("")
    const [loginerror,setLoginerror]=useState(false)
    const [checkbox,setCheckbox]=useState(false)
    const handleChange = (event) => {
        setField({...field,[event.target.name]: event.target.value,});
    };
    const handleSubmit = (e) => {
        e.preventDefault();

        axios.post("https://api.pecunovus.net/user/register",
         {real_name:field.firstname,last_name:field.lastname,email:field.email,password:field.password}).then((res) => {
        
       
         if(res.data.signup){
              
             window.location.replace('/')
         }else{
             setError(res.data.message)
            setLoginerror(true)
            setTimeout(() => {
              setLoginerror(false)
              setError('')
            }, 3000); 
         }
           
        }).catch((err) => {
           console.log(err)
        })
    };
    return (
        <div>
             <div style={{display:'flex',flexFirection:'row',justifyContent:'center',alignItems:'center'}}>
              {loginerror? <div className='QR-home' style={{padding:'0px',border:'none',maxWidth:'200px',maxHeight:'40px',zIndex:'9999',marginTop:'100px'}}>
               <div className='header-Qr' style={{padding:'5px',width:'100%',borderRadius:'5px'}} >
                   <span style={{fontSize:'1rem',color:'white',fontWeight:'400'}} >{error}</span>
                   <button style={{cursor:'pointer'}} onClick={()=>setLoginerror(false)}>X</button>
               </div>
           
                 </div>:null}
          </div>

                 <div className="login-box" style={{flexDirection:'row',justifyContent:'space-evenly'}}>
          
            <div className="login-card" style={{flex:'0.5'}}>
            
                {/* <FaUserShield size={80} className="login-logo" /> */}
                <h3 style={{color:'white'}}>Create your Pecu Wallet</h3>
                <p style={{color:'gray',marginTop:'0px',fontSize:'12px'}}>You will need your Pecu Novus Mobile Wallet to Log in. </p>
                <form onSubmit={handleSubmit}>
                <label>First Name</label>
                    <input placeholder="John" required name="firstname" 
                    onChange={handleChange} value={field?.firstname} />
                   
                    <label>Last Name</label>
                    <input placeholder="doe" required name="lastname" onChange={handleChange} value={field?.lastname} />
                   
                    <label>Email ID</label>
                    <input placeholder="Email ID" name="email" required type={"email"} onChange={handleChange} value={field?.email} />
                    <lable>Password</lable>
                    <input autoComplete="off" required  placeholder="password" type={"password"} name="password" onChange={handleChange} value={field?.password} />

                    <div className="extra-info" style={{justifyContent:'flex-start'}}>
                        {/* <span>
                            <input type={"checkbox"} defaultChecked />
                            Stay Logged in
                        </span> */}
                      <input required type={"checkbox"} onChange={()=>setCheckbox(!checkbox)} /><a href="/TermsOfService" style={{color:'orangered',marginLeft:'10px',textDecoration:'none'}}>I agree to terms and conditions</a>
                    </div>
                    <button type="submit" className="primary loginbtn">
                       Register
                    </button>
                    <span style={{width:'100%',textAlign:'center',fontSize:'10px'}}>OR</span>
                   <button className="primary signUpbtn" >
                   <a href="/" style={{color:'white',textDecoration:'none'}} >
            Login 
                    </a>
                   </button>
                    {/* <div className="extra-info">
                        <a href="/signup" className="signup-text">
                            Create New Wallet
                        </a>
                    </div> */}
                </form>
            </div>
          <div style={{flex:'0.5',display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
              <div style={{display:'flex',flexDirection:'row',alignItems:'center'}}>  <img src={logo} className='logo' width={"70px"} />    <p style={{color:'white',marginTop:'0px',fontSize:'12px',width:'500px'}}>Connect to the Pecu Terminal from virtually anywhere an internet connection is available. Your Pecu Novus Mobile Wallet is your Terminal Key which gives you access to the entire Pecu Terminal.</p>
          </div>
              <img src={loginRight} style={{width:'100%'}} />
           </div>
        </div>  
        </div>
      
    );
}
