import Footer from "./components/footer/footer";
import Header from "./components/header/header";
import Main from "./components/main/main";
import jwt_decode from "jwt-decode";
import {
  BrowserRouter as Router,
  Route,
  Routes ,
  Redirect
} from 'react-router-dom';
import { useEffect, useState } from "react";
import Login from "./components/Login/Login";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsOfService from "./TermsOfService";


import Signup from "./components/signup/signup";
import PrivacypolicyEscrow from "./privacypolicyEscrow";
function App() {
  const [userToken,setUserToken]= useState("")
  const [user,setUser]= useState(null)
  const [showQr,setShowQr]= useState(false)
  const [sideDrawer,setSideDrawer] =useState(false)
  const [allowAccess,setAllowAccess]=useState(false)
  const saveUser=(e)=>{
    setUser(jwt_decode(e))
  }
 useEffect(()=>{
   let data=localStorage.getItem('pn_secretcookie')
 
  if(data){
   
      setUser(JSON.parse(data))
  }
 },[])


 const updatepubkey=(pub_key)=>{
     let newUser=user
     newUser.pub_key=pub_key
     setUser(newUser)
     localStorage.setItem('pn_secretcookie',JSON.stringify(newUser))
 }
const handleUserToken=(e)=>{
  setUser(e)

}

const logOut=()=>{
  localStorage.removeItem('pn_secretcookie')

  setUser([])
  window.location.reload()

}



useEffect(()=>{
  setTimeout(() => {

   logOut()
  }, 1800000);
},[])

const handleAllowAccess=(e)=>{
setAllowAccess(e)
}
  return (
    // <div >
    //  <Header />
    //  <Main />
    //  <Footer />
    // </div>
    <Router>
   <Header allowAccess={allowAccess} updatepubkey={(pub_key)=>{updatepubkey(pub_key)}} resetSidebar={sideDrawer} showQr={()=>{setShowQr(true)}} sideDrawer={(sideDrawer)=>{setSideDrawer(sideDrawer)}} user={user} logOut={()=>{logOut()}} />
   <Routes >
   
     
       <Route path='/' element={ user&& user.loggedIn?<Main allowAccess={(e)=>handleAllowAccess(e)} showSideDrawer={(sideDrawer)=>{setSideDrawer(sideDrawer)}} closeQr={()=>setShowQr(false)} showQr={showQr} sideDrawer={sideDrawer} user={user} />
       :<Login  loginData={(e)=>{handleUserToken(e)}} />} />
       <Route path='/PrivacyPolicy' element={<TermsOfService />} />
       <Route path='/TermsOfService' element={<PrivacyPolicy />} />
       <Route path='/escrowTermsOfService' element={<PrivacypolicyEscrow />} />
      
       <Route path='/Signup' element={<Signup />} />
      
       {/* <Route path='/Dashboard' element={user &&user.tokenUser?<Main />:<Login />} /> */}
       
   </Routes >
   <Footer />
 </Router>
  );
}

export default App;
